import React from "react";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

interface LoadingBackdropProps {
  open?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const LoadingBackdrop = (props: LoadingBackdropProps) => {
  // const classes = LayoutUtils.useStyles()
  const classes = useStyles();

  const open = props.open ? props.open : false;

  return (
    <Backdrop open={open} classes={{ root: classes.root }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
