import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

// TODO: figure out how to handle makeStyles thing...
export type DiscogsClasses =
  | "container"
  | "loadBtnRow"
  | "loadBtnWrapper"
  | "loadBtnProgress"
  | "itemCard"
  | "itemModal"
  | "itemCardImage"
  | "showRecordsIcon"
  | "buttonLink"
  | "navLink"
  | "list"
  | "navClose"
  | "navTitle"
  | "dialogLink"
  | "backToTopBtn"
  | "itemDrawerRight"
  | "itemDrawerBottom"
  | "artistButton"
  | "yearBtn"
  | "gridItem";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    loadBtnRow: {
      textAlign: "center",
      marginTop: "10px",
      marginBottom: "20px",
    },
    loadBtnWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    loadBtnProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    itemCard: {
      minWidth: "400px",
    },
    itemModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    itemCardImage: {
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "0 auto",
    },
    showRecordsIcon: {
      marginLeft: theme.spacing(1),
    },
    buttonLink: {
      color: "inherit",
      textDecoration: "none",
    },
    dialogLink: {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    navLink: {
      textDecoration: "none",
    },
    list: {
      width: "250px",
    },
    navClose: {
      justifyContent: "flex-end",
    },
    navTitle: {
      flexGrow: 1,
    },
    backToTopBtn: {
      position: "fixed !important" as "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    itemDrawerRight: {
      minWidth: "400px",
      maxWidth: "400px",
      "& img": {
        width: "350px",
        margin: "0 auto",
      },
      [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        minWidth: "300px",
        maxWidth: "300px",
        "& img": {
          width: "275px",
          margin: "0 auto",
        },
      },
      marginBottom: "10px",
    },
    artistButton: {
      color: "inherit",
      textDecoration: "none",
      "& button": {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        whiteSpace: "nowrap",
      },
    },
    itemDrawerBottom: {
      marginBottom: "10px",
      "& img": {
        maxWidth: "80%",
        margin: "0 auto",
      },
    },
    yearBtn: {
      color: "inherit",
      textDecoration: "none",
      marginLeft: "20px",
    },
    gridItem: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
