import React, { lazy, Suspense } from "react";
import * as LayoutUtils from "./LayoutUtils";
import { Container } from "@material-ui/core";
import { BrowserRouter, Route } from "react-router-dom";
import LoadingBackdrop from "./layout/LoadingBackdrop";
import DiscogsProvider from "./DiscogsContext";

const Releases = lazy(() => import("./Releases"));
const Instances = lazy(() => import("./Instances"));
const Artists = lazy(() => import("./Artists"));
const Labels = lazy(() => import("./Labels"));
const Navigation = lazy(() => import("./Navigation"));
const BackToTop = lazy(() => import("./BackToTop"));

export default function DiscogsApp() {
  const classes = LayoutUtils.useStyles();
  // const loading = LayoutUtils.loadingBackdrop(classes)

  return (
    <Suspense fallback={<LoadingBackdrop />}>
      <DiscogsProvider>
        <BrowserRouter>
          <Navigation />
          <Container maxWidth="lg" className={classes.container}>
            <Route exact path={["/", "/releases"]}>
              <Releases />
            </Route>
            <Route exact path="/instances" key="instances">
              <Instances />
            </Route>
            <Route exact path="/:type/:id/instances" key="type-instances">
              <Instances />
            </Route>
            <Route exact path="/artists">
              <Artists />
            </Route>
            <Route exact path="/labels">
              <Labels />
            </Route>
          </Container>
          <BackToTop />
        </BrowserRouter>
      </DiscogsProvider>
    </Suspense>
  );
}
