import React, { createContext, useReducer, Dispatch } from "react";

export enum DiscogsContextActions {
  SetNavTitle,
}

interface DiscogsState {
  navTitle: string;
}

interface DiscogsReducerAction {
  type: DiscogsContextActions;
  payload: any;
}

interface DiscogsContextProps {
  state: DiscogsState;
  dispatch: React.Dispatch<any>;
}

const initialState: DiscogsState = {
  navTitle: "",
};

const reducer = (state: DiscogsState, action: DiscogsReducerAction) => {
  switch (action.type) {
    case DiscogsContextActions.SetNavTitle:
      return {
        ...state,
        navTitle: action.payload.title,
      };
    default:
      return state;
  }
};

export const DiscogsContext = createContext<DiscogsContextProps>(
  {} as DiscogsContextProps
);

export default function DiscogsProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DiscogsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DiscogsContext.Provider>
  );
}
