import React from "react";
import DiscogsApp from "./DiscogsApp";
import { createMuiTheme, ThemeProvider, CssBaseline } from "@material-ui/core";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

export default function Main() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <DiscogsApp />
    </ThemeProvider>
  );
}
